import { storeComponent } from '../components/helpers.js'
import lepaya from '../http/lepaya.js'
import * as R from 'ramda'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { cloneDeep } from 'lodash'

const action = storeComponent.action

export default storeComponent({
  actions: {
    setSupportEmployees: action([ 'supportEmployees' ]),
    setInitialClassroom: action([ 'initialClassroom' ]),
    setEditing: action([ 'editing' ]),
    setErrors: action([ 'errors' ]),
    setStartDate: action([ 'classroom', 'startDate' ]),
    setEndDate: action([ 'classroom', 'endDate' ]),
    setStartTime: action([ 'classroom', 'startTime' ]),
    setEndTime: action([ 'classroom', 'endTime' ]),
    setTimeZone: action([ 'classroom', 'timeZone' ]),
    setLocationName: action([ 'classroom', 'location', 'name' ]),
    setLocationRoom: action([ 'classroom', 'location', 'room' ]),
    setLocationAddress: action([ 'classroom', 'location', 'address' ]),
    setLocationType: action([ 'classroom', 'location', 'classroomType' ]),
    setAdditionalInfo: action([ 'classroom', 'additionalInfo' ]),
    setCalendarInviteDescription: action([ 'classroom', 'calendarInviteDescription' ]),
    setParams: action([ 'classroomParams' ]),
    setClassroomTrainer: action([ 'classroom', 'trainer' ]),
    getSupportEmployees: action([ 'supportEmployees' ], getSupportEmployees),
    getEvent: action([ 'classroom' ], getEvent),
    initialize,
    persistClassroom,
    updateClassroom,
    createClassroom,
    updateCalendarInviteText,
    changeClassroomTrainer,
    resetClassroomTrainer
  }
})

function initialize (context, to) {
  const promises = []
  promises.push(context.dispatch('setParams', to.params))
  promises.push(context.dispatch('getSupportEmployees', to.params)
    .then(() => context.dispatch('getEvent', to.params)))
  promises.push(context.dispatch('setErrors', {}))
  return Promise.all(promises)
}

function getSupportEmployees (_, { companyCode }) {
  return lepaya.get(`/companies/${companyCode}/supportEmployees`)
    .then(({ data: supportEmployees }) => sortSupportEmployees(supportEmployees))
}

function sortSupportEmployees (supportEmployees) {
  if (supportEmployees) {
    supportEmployees.sort(function (a, b) {
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return -1
      else if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1
      return 0
    })
  }
  return supportEmployees
}

function getEvent (context, { companyCode, id, classroomId }) {
  const initialClassroom = {
    type: 'classroom',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    timeZone: moment.tz.guess(),
    calendarInviteDescription: '',
    additionalInfo: '',
    location: {
      name: null,
      room: null,
      address: null,
      classroomType: 'face_to_face'
    }
  }

  if (R.isNil(classroomId)) {
    context.dispatch('setEditing', false)
    context.dispatch('setInitialClassroom', {})
    lepaya.get(`/companies/${companyCode}/journeys/${id}/calendarInviteDescription`)
      .then(({ data: response }) => {
        initialClassroom.calendarInviteDescription = response.calendarInviteDescription
      })
    return Promise.resolve(initialClassroom)
  } else {
    return lepaya.get(`/companies/${companyCode}/journeys/${id}/events/${classroomId}`)
      .then(({ data: event }) => {
        context.dispatch('setEditing', true)
        const classroom = {
          ...event,
          startTime: event.startTime,
          endTime: event.endTime,
          timeZone: event.timeZone,
          location: {
            name: (event.location && event.location.name) || null,
            room: (event.location && event.location.room) || null,
            address: (event.location && event.location.address) || null,
            classroomType: (event.location && event.location.classroomType) || null
          },
          additionalInfo: event.additionalInfo || '',
          calendarInviteDescription: event.calendarInviteDescription || '',
          trainer: event.supportTeam.find(member => member.journeyRole === 'trainer')
        }
        context.dispatch('setInitialClassroom', R.clone(classroom))
        return classroom
      })
  }
}

function createClassroom (context, classroom) {
  const { companyCode, id: journeyId } = context.state.classroomParams
  return lepaya.post(`/companies/${companyCode}/journeys/${journeyId}/events`, classroom)
}

function updateClassroom (context, classroom) {
  const { companyCode, id: journeyId, classroomId } = context.state.classroomParams
  return lepaya.put(`/companies/${companyCode}/journeys/${journeyId}/events/${classroomId}`, classroom)
}

function updateCalendarInviteText (context, classroom) {
  const { companyCode, id: journeyId, classroomId } = context.state.classroomParams
  return lepaya.put(`/companies/${companyCode}/modules/${journeyId}/classrooms/${classroomId}`, classroom)
}

function checkIfEndTimeIsOnNextDay (classroomInfo) {
  const clonedClassroomInfo = cloneDeep(classroomInfo)
  const startDate = momentTz.tz(`${clonedClassroomInfo.startDate}T${clonedClassroomInfo.startTime}:00`, clonedClassroomInfo.timeZone)
  const endDate = momentTz.tz(`${clonedClassroomInfo.endDate}T${clonedClassroomInfo.endTime}:00`, clonedClassroomInfo.timeZone)
  let clonedEndDate
  if (moment(endDate).isBefore(startDate, 'minutes')) {
    const endDateTomorrow = moment(endDate).add(1, 'days')
    clonedEndDate = endDateTomorrow.format('YYYY-MM-DD')
  } else if (clonedClassroomInfo.endTime < clonedClassroomInfo.startTime && classroomInfo.id) {
    clonedEndDate = clonedClassroomInfo.endDate
  } else {
    clonedEndDate = clonedClassroomInfo.startDate
  }
  return clonedEndDate
}

function persistClassroom (context, classroom) {
  context.dispatch('setErrors', {})
  const endDate = checkIfEndTimeIsOnNextDay(classroom)
  const payload = {
    ...classroom,
    endDate: endDate,
    supportTeam: classroom.trainer ? [
      {
        journeyRole: 'trainer',
        employeeId: classroom.trainer.id,
        previousEmployeeId: context.state.initialClassroom.trainer ? context.state.initialClassroom.trainer.id : null
      }
    ] : []
  }

  return context.dispatch(
    !R.isNil(context.state.classroomParams.classroomId) && !R.isNil(context.state.classroomParams.sfid)
      ? 'updateCalendarInviteText'
      : R.isNil(context.state.classroomParams.classroomId)
        ? 'createClassroom'
        : 'updateClassroom', payload)
    .catch(({ response: { data: errors } }) =>
      context.dispatch('setErrors', errors)
        .then(() => Promise.reject(errors)))
}

function changeClassroomTrainer (context, { trainerId }) {
  const trainer = context.state.supportEmployees.find(employee => employee.id === parseInt(trainerId))
  return context.dispatch('setClassroomTrainer', trainer)
}

function resetClassroomTrainer (context) {
  return context.dispatch('setClassroomTrainer', null)
}

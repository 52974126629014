import lepaya from '@/http/lepaya'

export default {
  fetchBites (id, companyCode) {
    return lepaya.get(`/companies/${companyCode}/journeys/${id}/bites`)
  },
  async createBite (data) {
    const { id, companyCode, bite } = data
    return lepaya.post(`/companies/${companyCode}/journeys/${id}/bites`, bite)
  },
  async updateBite (data) {
    const { id, companyCode, bite } = data
    return lepaya.put(`/companies/${companyCode}/journeys/${id}/bites/${bite.id}`, bite)
  },
  autoSchedulingBites (companyCode, id) {
    return lepaya.put(`/companies/${companyCode}/journeys/${id}/bite-scheduling`)
  },
  publishBites (id, companyCode, bites) {
    return lepaya.put(`/companies/${companyCode}/journeys/${id}/publish-bites`, { bites: bites })
  },
  archiveBites (data) {
    const { id, companyCode, bites } = data
    return lepaya.put(`/companies/${companyCode}/journeys/${id}/archive-bites`, { bites })
  },
  bucketizeModule (data) {
    const { id, companyCode, sessionBites } = data
    return lepaya.put(`/companies/${companyCode}/journeys/${id}/bucketize`, { sessionBites })
  }
}
